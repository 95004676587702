import BlockContent from "@sanity/block-content-to-react"
import { addDays, isFuture, isPast } from "date-fns"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { flatten } from "ramda"
import React, { FC } from "react"
import BestilDag from "../components/bestilling/bestil-dag"
import BestilKurv, {
  BestillingsLinje,
} from "../components/bestilling/bestil-kurv"
import Header from "../components/header"
import { KontaktAdresse } from "../components/KontaktAdresse"
import Layout from "../components/layout"
import LeftOverlay from "../components/left-overlay"
import OrangeBanner from "../components/orange-banner"
import { blockSerializer } from "../utils/sanity-helper"
import { projectId, dataset } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"

export const query = graphql`
  query {
    ugemenu: sanityUgemenu {
      _rawBody
      id
      _rawMainImage
      mainImage {
        asset {
          fluid(maxWidth: 1152, maxHeight: 420) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    buffetEtImage: file(name: { eq: "buffet" }) {
      childImageSharp {
        id
        fluid(maxWidth: 536, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    buffetToImage: file(name: { eq: "tallerkener" }) {
      childImageSharp {
        id
        fluid(maxWidth: 536, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const UgeMenuTemplate: FC<any> = ({ data, pageContext }) => {
  const aar = data.senestemenu.nodes[0].aar
  const uge = data.senestemenu.nodes[0].uge
  const { menu, mandagString } = pageContext
  const mandag = new Date(mandagString)

  const denneUge = ["mandag", "tirsdag", "onsdag", "torsdag", "fredag"].map(
    (navn, index) => {
      return { navn, date: addDays(mandag, index) }
    }
  )

  const varer: BestillingsLinje[] = flatten(
    denneUge.map((dag, index) => {
      return [
        {
          vare: {
            name: menu[dag.navn].dagensRet,
            nameDetails: "Voksen",
            dateString: dag.date.toISOString(),
            details: menu[dag.navn].dagensRetDetaljer,
            sku: `${menu._id}-${dag.date.getDate()}-${index}`,
            price: menu[dag.navn].prisVoksen * 100,
            currency: "DKK",
          },
          antal: 0,
          dag: dag.date,
          lukket: menu[dag.navn].lukket,
        },
        {
          vare: {
            name: menu[dag.navn].dagensRet,
            nameDetails: "Barn",
            dateString: dag.date.toISOString(),
            details: menu[dag.navn].dagensRetDetaljer,
            sku: `${menu._id}-${dag.date.getDate()}-${index + 1}`,
            price: menu[dag.navn].prisBarn * 100,
            currency: "DKK",
          },
          antal: 0,
          dag: dag.date,
          lukket: menu[dag.navn].lukket,
        },
        {
          vare: {
            name: menu[dag.navn].dagensGroenneRet,
            nameDetails: "Voksen",
            dateString: dag.date.toISOString(),
            details: menu[dag.navn].dagensGroenneRetDetaljer,
            sku: `${menu._id}-${dag.date.getDate()}-${index + 2}`,
            price: menu[dag.navn].prisVoksen * 100,
            currency: "DKK",
          },
          antal: 0,
          dag: dag.date,
          lukket: menu[dag.navn].lukket,
        },
        {
          vare: {
            name: menu[dag.navn].dagensGroenneRet,
            nameDetails: "Barn",
            dateString: dag.date.toISOString(),
            details: menu[dag.navn].dagensGroenneRetDetaljer,
            sku: `${menu._id}-${dag.date.getDate()}-${index + 3}`,
            price: menu[dag.navn].prisBarn * 100,
            currency: "DKK",
          },
          antal: 0,
          dag: dag.date,
          lukket: menu[dag.navn].lukket,
        },
        {
          vare: {
            name: "Dagens salat",
            nameDetails: "Dagens salat",
            dateString: dag.date.toISOString(),
            details: "",
            sku: `${menu._id}-${dag.date.getDate()}-${index + 4}`,
            price: (menu[dag.navn].prisSalat ?? 0) * 100,
            currency: "DKK",
          },
          antal: 0,
          dag: dag.date,
          lukket: menu[dag.navn].lukket,
        },
      ]
    })
  )

  const visKurv = (dag: { date: Date; navn: string }) =>
    isFuture(dag.date) &&
    varer.find((linje) => linje.dag.getDate() === dag.date.getDate())?.vare.name

  return (
    <Layout title="Bestilling" aar={aar} uge={uge}>
      {menu.ferie ? (
        <>
          <LeftOverlay title={`Ferielukket uge ${menu.uge}`}>
            <Img
              backgroundColor
              fluid={getFluidImage(
                data.ugemenu._rawMainImage,
                data.ugemenu.mainImage.asset.fluid,
                1152,
                420
              )}
            />
          </LeftOverlay>
          <div className="grid max-w-3xl grid-cols-1 gap-4 px-8 mx-auto md:grid-cols-1 md:px-0">
            <div className="px-8 mt-4 mb-8 text-xl text-center">
              Grundet ferie er det ikke muligt at bestille dagens ret i uge
              {" " + menu.uge}
              <br />
              For øvrige catering forespørgsler kontakt os gerne:
              <KontaktAdresse />
            </div>
          </div>
        </>
      ) : (
        <>
          <LeftOverlay title={`Bestilling uge ${menu.uge}`}>
            <Img
              backgroundColor
              fluid={getFluidImage(
                data.ugemenu._rawMainImage,
                data.ugemenu.mainImage.asset.fluid,
                1152,
                420
              )}
            />
          </LeftOverlay>
          <div className="px-8 mt-4 mb-8 text-xl text-center">
            <BlockContent
              blocks={data.ugemenu._rawBody}
              serializers={blockSerializer()()}
              projectId={projectId}
              dataset={dataset}
            />
          </div>

          {denneUge.some((dag) => visKurv(dag)) && (
            <div className="sticky top-0 z-50 sm:px-8 lg:hidden">
              <BestilKurv aar={menu.aar} uge={menu.uge} />
            </div>
          )}
          <div className="grid grid-cols-1 gap-8 my-8 sm:px-8 md:grid-cols-2 lg:grid-cols-3">
            {denneUge
              .filter((dag) => isFuture(dag.date))
              .map((dag, index) => (
                <BestilDag
                  key={index}
                  linjer={varer.filter(
                    (linje) => linje.dag.getDate() === dag.date.getDate()
                  )}
                  dag={dag.date}
                />
              ))}
            {denneUge.some((dag) => visKurv(dag)) && (
              <div className="hidden lg:block">
                <BestilKurv aar={menu.aar} uge={menu.uge} />
              </div>
            )}
            {denneUge
              .filter((dag) => isPast(dag.date))
              .map((dag, index) => (
                <BestilDag
                  key={index}
                  linjer={varer.filter(
                    (linje) => linje.dag.getDate() === dag.date.getDate()
                  )}
                  dag={dag.date}
                />
              ))}
          </div>
        </>
      )}

      {false && (
        <OrangeBanner
          header="Mad på abonnement"
          body={
            <>
              Som kunde hos Øland kan du tegne et abonnement til et fast antal
              personer Maden tager maks. 15 min. at gøre klar, i en 180 grader
              forvarmet ovn. Hurtigt, nemt og lækkert.
            </>
          }
          button="Læs mere"
        />
      )}
      <div className="grid grid-cols-1 gap-8 px-8 mt-16 md:grid-cols-2">
        <Link to="/catering">
          <Img fluid={data.buffetEtImage.childImageSharp.fluid} />
          <Header>Mad til jeres arrangement</Header>
          <p>Læs mere om vores populære buffeter som du også selv kan præge</p>
        </Link>
        <Link to="/frokostordning">
          <Img fluid={data.buffetToImage.childImageSharp.fluid} />
          <Header>Frokostordning</Header>
          <p>Vi kommer med økologisk mad til din virksomhed, hver dag</p>
        </Link>
      </div>
    </Layout>
  )
}

export default UgeMenuTemplate
